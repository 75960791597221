import React from "react";

const Error = () => {
  return (
    <>
      <h1>404</h1>
      <a href="/">Return Home</a>
    </>
  );
};

export default Error;
